<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Choices from "choices.js";

import {
  required,
} from "vuelidate/lib/validators";

import { getPMApi } from '@/api/pm'
import { autoComplete, financial } from '@/api/misc'

import moment from 'moment'

/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {
    if (this.expense_type == 'Expense') {
      return {
        pm_month : {required},
        selected_amount: { required },
        selected_gst: { required },
        selected_pm: { required },
        post_date: { required },

      }
    } else {
      return {
        pm_month : {required},
        selected_amount: { required },
        selected_gst: { required },
        selected_pm: { required },
        post_date: { required },

      }
    }

  },


  methods: {

    formSubmit() {

      let data = {
        pm_month  : this.pm_month,
        post_date: this.post_date,
        pm_list  : []
      }
      let data_error = false

      if (data_error == true) {
        this.has_data_error = true
        return
      }

      this.listingData.map(e => {
        data.pm_list.push(...e.ap_list)
      })
      getPMApi().post_pm_ap(data).then(res => {
        if (res.errCode == 0) {
          this.$alertify.message("Post AP  successfully");
          this.$router.push({ name: 'pm-property-list' })
        } else {
          this.$alertify.error(" Post  AP failed:" + res.errCode +' msg:' + res.msg);
        }
      })

    },

    onPMSelected(evt) {
      this.selected_pm_obj = Object.assign({}, evt)
      this.selected_pm = evt.str_id
    },



    onAddProperty() {
      this.$v.$touch()
      if (this.$v.$invalid == true) {
        return
      }

      let pm = this.listingData.find(e => e.pm_id == this.selected_pm)

      if (!pm) {
        pm = {
          pm_id: this.selected_pm,
          pm: this.selected_pm_obj,
          balance_available: this.selected_pm_obj.balance_available,
          ap_list: [],
          management_fee_amount: 0,
          placement_fee_amount : 0,
          nr4_amount           : 0,
          owner_amount         : 0,
          ending_balance: this.selected_pm_obj.balance_available,
        }
        this.listingData.push(pm)

      }

      pm.ap_list.push({
        type      : this.ap_type,
        total     : Number(this.selected_total),
        amount    : Number(this.selected_amount),
        gst       : Number(this.selected_gst),

        tmp_id    : this.temp_id++,
        post_date : this.post_date,
        pm_id     : pm.pm_id,
        remarks   : this.remarks,
      })


      
      if (this.ap_type == 'AGENT1' ) {
        pm.placement_fee_amount += Number(this.selected_total)
      } else if (this.ap_type == 'AGENT' ) {
        pm.management_fee_amount += Number(this.selected_total)
      } else if (this.ap_type == 'NR4') {
        pm.nr4_amount += Number(this.selected_total)
      } else if (this.ap_type == 'OWNER') {
        pm.owner_amount += Number(this.selected_total)
      }

      this.update_pm_balance(pm)


      this.total_amount += Number(this.selected_total)


      this.$alertify.message("PM AP  was Added to List");
    },

    removePMEntry(pm_obj, item) {
      let pm = pm_obj
      let pm_ap = pm.ap_list.find(e => e.tmp_id == item.tmp_id)

      
      pm.ap_list = pm.ap_list.filter(e => e.tmp_id != item.tmp_id)
      
      if (pm_ap.type == 'AGENT1') {
        pm.placement_fee_amount -= Number(item.amount)
      } else if (pm_ap.type == 'AGENT') {
        pm.management_fee_amount -= Number(item.amount)
      } else if (pm_ap.type == 'NR4') {
        pm.nr4_amount -= Number(item.amount)
      } else if (pm_ap.type == 'OWNER') {
        pm.owner_amount -= Number(item.amount)
      }

      
      this.update_pm_balance(pm)
      if (pm.ap_list.length <= 0) {
        this.listingData = this.listingData.filter(e => e.pm_id != pm_obj.pm_id)
      }

    },


    onSearchPM(str, is_init_pm = false) {
      autoComplete({ types: ['PM'], str: str, selection: ['property_id', 'o_code', 'address', 'balance_available', 'sum_rent'] }).then(res => {
        if (res.errCode == 0) {
          this.searched_pm_data = []
          res.data.map(e => {
            this.searched_pm_data.push(e)
          })
          if (is_init_pm) {
            this.selected_pm_obj = this.searched_pm_data[0]
          }
          
        }
      })
    },


    onListUploaded(data, resp) {
      (data);
      if (resp.errCode == 0) {

        this.listingData = []
        this.listingData.push(...resp.data.list)

        this.listingData.map(e => {
          e.management_fee_amount   = e.mf_amount + e.mf_gst_amount
          e.owner_amount            = e.bto_amount
          e.placement_fee_amount    = e.pf_amount + e.pf_gst_amount
          e.ap_list                 = e.je_list
          this.update_pm_balance(e)
        })

        
        if (resp.data.err  != 0) {
          this.has_data_error = true
          this.data_err_msg   = resp.data.note
        }

       // this.total_amount += Number(r.amount)
  
      } else {
        this.$alertify.error(" Upload  AP failed:" + resp.errCode +" msg:"+resp.msg);
        this.has_data_error = true
        this.data_err_msg   = resp.msg
      }
    },



    update_pm_balance(pm) {

      pm.ending_balance = pm.balance_available - pm.management_fee_amount- pm.nr4_amount - pm.owner_amount - pm.placement_fee_amount
      if ( pm.ending_balance <= 0) {
        pm._rowVariant   = 'danger'
      } else {
        pm._rowVariant   = ''
      }
    }



  },

  data() {
    return {
      title: "PM",
      items: [
        {
          text: "PM",
          href: "/pm/property_list",
        },
        {
          text: "RTA List",
          href: "/pm/property_list",
        },
        {
          text: "Post PM AP",
          active: true,
        },
      ],


      has_data_error: false,
      file: {},
      post_date: '',
      ap_type: 'AGENT',
      selected_pm: '',
      selected_pm_obj: {},
      selected_total: '',
      selected_amount: '',
      selected_gst: '',

      temp_id: 12001,
      pm_month: '',

      remarks : '',
      listingData: [],
      searched_pm_data: [],
      dropzoneOptions: {
        url: "/AwsFileUpload/s3_upload_multi",
        thumbnailWidth: 150,
        maxFilesize: 50,
      },

      total_amount: 0
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    vueDropzone: vue2Dropzone,
    VueBootstrapTypeahead,

  },
  mounted() {
    if (this.$route.query.property_id) {
      this.selected_pm = new String(this.$route.query.property_id)
      this.onSearchPM(this.$route.query.property_id, true)
    }

    this.$refs.property.inputValue = this.selected_pm
    // new Choices('#expense_type', { itemSelectText: '' })
    new Choices('#ap_type', { itemSelectText: '' })



    let today = moment()
    this.pm_month = today.format("YYYYMM")

  },

  watch: {
    selected_pm(new_pm_str) {
      this.onSearchPM(new_pm_str)
    },

    selected_amount(new_val) {
      if (this.ap_type =='NR4' || this.ap_type=='OWNER') {
        this.selected_gst =0
        this.selected_total = financial(Number(this.selected_amount)  + Number( this.selected_gst), 2)
      } else {
        this.selected_gst = financial(Number(new_val) * 0.05, 2)
        this.selected_total = financial(Number(this.selected_amount)  + Number( this.selected_gst), 2)
       
      }
     
    },

    selected_gst(new_val) {
      this.selected_total = financial(Number(this.selected_amount)  + Number(new_val), 2)
    }
  }


};
</script>



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">


      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <h5 class="card-title mb-4">Basic Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-3">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">PM Select</h5>
                  </div>
                </div>
                <div class="card-body">


                  <div class="row">
                    <div class="col-md-8">

                      <div class="row">
                        <div class="col-lg-3">
                          <div class="mb-3">
                           
                            <flat-pickr v-model="pm_month" placeholder="Select a month" class="form-control"
                              :config="{ dateFormat: 'Ym', altFormat: 'Y-m', ariaDateFormat: 'Y-m' }"
                              :class="{ 'is-invalid': $v.pm_month.$error }"></flat-pickr>
                            <div v-if="$v.pm_month.$error" class="invalid-feedback">
                              <span v-if="!$v.pm_month.required">This value is required.</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="mb-3">
                           
                            <div class="mb-3">

                              <flat-pickr v-model="post_date" placeholder="Select a date" class="form-control"
                                :class="{ 'is-invalid': $v.post_date.$error }"></flat-pickr>
                              <div v-if="$v.post_date.$error" class="invalid-feedback">
                                <span v-if="!$v.post_date.required">This value is required.</span>
                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="col-lg-4">
                          <div class="mb-3">
                          
                            <select id="ap_type" class="form-control" v-model="ap_type">
                              <option value="NR4">NR4</option>
                              <option value="AGENT">Management Fee</option>
                              <option value="AGENT1">Placement Fee</option>
                              <option value="OWNER">Balance To Owner</option>
                            </select>
                          </div>
                        </div>
                        
                      </div>


                      <div class="row">
                        <div class="col-lg-2">
                          <div class="mb-3">
                         
                            <vue-bootstrap-typeahead ref="property" v-model="selected_pm" :value="selected_pm"
                              :data="searched_pm_data" :inputClass="{ 'is-invalid': $v.selected_pm.$error }"
                              :serializer="s => s.str_id" :foramterDisplay="s => s.str_id" @hit="onPMSelected($event)"
                              autocomplete="off" />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                           
                            <div class="mb-3">

                              <input class="form-control" v-model="selected_amount" placeholder="Amount"
                                :class="{ 'is-invalid': $v.selected_amount.$error }" />
                              <div v-if="$v.selected_amount.$error" class="invalid-feedback">
                                <span v-if="!$v.selected_amount.required">This value is required.</span>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-lg-2" :class="ap_type=='NR4' || ap_type =='OWNER'?'d-none':'d-block'">
                          <div class="mb-3">
                           
                            <div class="mb-3">

                              <input class="form-control" v-model="selected_gst"
                                :class="{ 'is-invalid': $v.selected_gst.$error }" placeholder="Gst"/>
                              <div v-if="$v.selected_gst.$error" class="invalid-feedback">
                                <span v-if="!$v.selected_gst.required">This value is required.</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                           
                            <div class="mb-3">

                              <input class="form-control" v-model="selected_total" readonly />
                              
                            </div>
                          </div>
                        </div>


                        <div class="col-lg-4">
                          <div class="mb-3">
                           
                            <div class="mb-3">
                              <input class="form-control" v-model="remarks" placeholder="Note"/>
                            </div>
                          </div>
                        </div>

                       

                        <div class="col-lg-2">
                          <div class="mt-4">
                            <b-button variant="primary" @click="onAddProperty">Add AP</b-button>
                          </div>
                        </div>

                      </div>

                    </div>



                    <div class="col-md-4">
                      <div class="row">

                        <vue-dropzone id="expense-dropzone" :use-custom-slot="true"
                          :options="{ url: '/adm/pm/form/update_post_ap_form', maxFilesize: 10, maxFiles: 1, paramName: 'form', thumbnailHeight: 80, thumbnailWidth: 80, addRemoveLinks: true }"
                          @vdropzone-success="onListUploaded">
                          <div>
                            <i class="display-4 text-muted "></i>
                            <h4>Upload  file to here.</h4>
                          </div>
                        </vue-dropzone>

                      </div>


                    </div>

                  </div>

                </div>


              </div>
              <!-- end card -->


              <div class="row">
                <b-alert show variant="danger" v-if="has_data_error">{{ data_err_msg }}</b-alert>
              </div>


              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Property AP Detail</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div data-simplebar>
                    <div class="table-responsive mt-3">
                      <div class="table align-middle table-nowrap">
                        <b-table :items="listingData"
                          :fields="['Code', 'EndingBalance', 'ManagementFee','PlacementFee', 'NR4', 'BalanceToOwner', 'NewEndingBalance']"
                          responsive="sm" :per-page="listingData.length" class="table-check">
                          <template #cell(Code)="data">
                            <feather :type="data.detailsShowing ? 'chevron-down' : 'chevron-right'" @click="data.toggleDetails">
                            </feather>
                            {{ data.item.pm_id }}
                          </template>
                          <template #cell(EndingBalance)="data">
                            ${{ data.item.balance_available.toLocaleString() }}
                          </template>
                          
                          <template #cell(ManagementFee)="data">
                            ${{ data.item.management_fee_amount.toLocaleString() }}
                          </template>
                          <template #cell(PlacementFee)="data">
                            ${{ data.item.placement_fee_amount.toLocaleString() }}
                          </template>
                          
                          <template #cell(NR4)="data">
                            ${{ data.item.nr4_amount.toLocaleString() }}
                          </template>
                          <template #cell(BalanceToOwner)="data">
                            ${{ data.item.owner_amount.toLocaleString() }}
                          </template>
                          <template #cell(NewEndingBalance)="data">
                            ${{ data.item.ending_balance.toLocaleString() }}
                          </template>

                          <template v-slot:row-details="row">
                            <div class="table align-middle table-nowrap" style="margin-left:30px;">
                                <b-table :items="row.item.ap_list" :fields="['Type', 'PostDate','Amount', 'Gst','Total', 'Action']"
                                    responsive="sm" :per-page="row.item.ap_list.list ? row.item.ap_list.length : 0"
                                    class="table-check col-md-10">

                                    <template #cell(Type)="data">
                                        {{ data.item.type }}
                                    </template>
                                    <template #cell(PostDate)="data">
                                        {{ data.item.post_date }}
                                    </template>

                                    <template #cell(Amount)="data">
                                        ${{ data.item.amount.toLocaleString() }}
                                    </template>
                                    <template #cell(Gst)="data">
                                        ${{ data.item.gst.toLocaleString() }}
                                    </template>
                                    <template #cell(Total)="data">
                                        ${{ data.item.total.toLocaleString() }}
                                    </template>
                                    <template #cell(Action)="data">
                                        <b-dropdown  variant="white"  right  toggle-class="btn-link text-dark shadow-none"  >
                                        <template v-slot:button-content>
                                            <i class="uil uil-ellipsis-h"></i>
                                        </template>
                                        <b-dropdown-item @click="removePMEntry(row.item, data.item)">Remove</b-dropdown-item>
                                        </b-dropdown>

                                    </template>

                                  </b-table>
                            </div>
                          </template>

                        </b-table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- end card -->



              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                    <button type="button" class="btn btn-secondary w-sm" @click="$router.push({name: 'pm-property-list'})"> Cancel </button>
                    <button type="submit" class="btn btn-primary w-sm">Submit </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>

<style scoped>
#expense-dropzone {
  letter-spacing: 0.2px;
  height: 150px;
}
</style>